import styled from "styled-components";
import { mobileRange } from "../../common/styles/breakpoints";

export const ViewerContainer = styled.div`
    width: 90%;
    margin: auto; 
    margin-top: 100px;

    mark {
        background-color: rgba(255, 255, 0, 0.4);
        color: inherit;
        padding: 0;
    }

    .react-pdf__Document {
        // width: 70%;
        width: min-content !important;
        margin: auto;
        box-shadow: 0 0 10px black;
        
        // @media ${mobileRange} {
        //     width: 100%;
        // }
    }

    .react-pdf__Page {
        // width: 100%;
        width: min-content !important;
    }

    .react-pdf__Page:not(:last-child) {
        border-bottom: 10px solid black;
    }

    // .react-pdf__Page__canvas {
    //     @media ${mobileRange} {
    //         width: 100% !important;
    //     }
    // }

    // .react-pdf__Page__textContent {
    //     @media ${mobileRange} {
    //         width: 100% !important;
    //     }

    //     span {
    //         @media ${mobileRange} {
    //             left: 9% !important;
    //         } 
    //     }
    // }
`;