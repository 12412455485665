import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import _isEmpty from "lodash/isEmpty";
import axios from "axios";
import { Prompt } from "react-router-dom";
import Joyride from "react-joyride";

import * as styled from "./style";
import searchResources from "../../config/content/searchPage";
import AutoSuggestSearch from "../../components/auto-suggest-search";
import withTransition from "../../components/with-transition";
import * as actions from "./actions";
import OverlayImage from "../../assets/tour-images/black_img.jpeg";
import SearchPopUp from "../../components/search-popup";
import SearchSRDResults from "./search-srd-results/index.js";
import SearchLoader from "../../components/search-loader";
import PIResults from "./pi-results";
import { endpoint } from "../../config/app.config";
import { srdConverterUrl } from "../../config/srd.config";
import PiDetails from "../../components/piDetails";
import PiLoaderComponent from "../../components/PiLoader";
import SrdPage from "../../components/srdPageComponent/srdPageComponent.jsx";
import tooltipResources from "../../config/content/tooltip";
import HelpToolTip from "../../components/tooltip-search";
import InformativePopups from "../../components/informative-popups";
import DidItHelpSection from "../../components/search-did-this-help";
import FeedbackToolTip from "../../components/feedback-tooltip";
import MemberContactInfo from "./member-contact-info";
import AlertIconGreen from "../../assets/images/alert-icon-green.svg";


const mapStateToProps = (state) => {
  return {
    isLoading: state.searchResultReducer.loading,
    searchResult: state.searchResultReducer.searchResult,
    searchInput: state.autoSuggestReducer.searchData,
    searchQuery: state.autoSuggestReducer.autoSuggest,
    firstsearch: state.searchResultReducer.firstSearch,
    expert: state.searchResultReducer.expert,
    manufacturerDetails: state.searchResultReducer.manufacturerDetails,
    subscriptionMessage: state.homeReducer.message,
    toolTipIndex: state.homeReducer.toolTipIndex,
    tourStatus: state.homeReducer.tourStatus,
    srd_summary: state.searchResultReducer.srd_summary,
    piDetails: state.searchResultReducer.piDetails,
    isPiLoading: state.searchResultReducer.piLoading,
    sectionNameUsingDCode: state.searchResultReducer.sectionNameUsingDCode,
    error: state.searchResultReducer.error,
    usersearchQuery: state.searchResultReducer.searchQuery
  };
};

const GADegrees = ["MD/DO", "NP/APN", "RPh/PharmD", "PA", "RN", "NE", "PT"];
let informativePopupSteps = [
  {
    content: <InformativePopups stepNumber={1} totalSteps={2}>Click to view the Drug Information Response about a specific product.</InformativePopups>,
    locale: { skip: <span aria-label="skip">Later</span>,
      next: <strong aria-label="next">Next</strong>,
      last: <strong aria-label="last">Got it</strong>},
    placement: "left",
    target: "#viewDocument-btn-srd",
    disableBeacon: true,
    floaterProps: {
      disableAnimation: true
    },
    styles: {
      options: {
        width: 390
      }
    }
  },
  {
    content: <InformativePopups stepNumber={2} totalSteps={2}>Click to view Full Prescribing Information about a specific product.</InformativePopups>,
    locale: { skip: <strong aria-label="skip">Later</strong>,
      back: <span aria-label="back">Previous</span>,
      next: <strong aria-label="next">Next</strong>,
      last: <strong aria-label="last">Got it</strong>},
    placement: "left",
    target: "#viewDocument-btn-pi",
    disableBeacon: true,
    floaterProps: {
      disableAnimation: true
    },
    styles: {
      options: {
        width: 390
      }
    }
  }
]

const NewSearch = (props) => {
  const [searchData, setSearchData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [productTerm, setProductTerm] = useState("");
  const [searchError, setSearchError] = useState(false);
  const [termError, setTermError] = useState(false);
  const [jaanpRedirect, setJaanpRedirect] = useState(false);
  const [fromOprx, setFromOprx] = useState(false);
  const [isFirstTime, setIsFirstTime] = useState(false);
  const [role, setRole] = useState("")
  const [roleDescription, setRoleDescription] = useState("");
  const [adsId, setAdsId] = useState("");
  const [isPiLoaded, setIsPiLoaded] = useState(false);
  const [displayMirPopup, setDisplayMirPopup] = useState(false);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const [urlForWebSrdIFrame, setUrlForWebSrdIFrame] = useState("");
  const [webSrdItemDetails, setWebSrdItemDetails] = useState(null);
  const [showWebSrdPage, setShowWebSrdPage] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState(null);
  const [informativePopupRun, setInformativePopupRun] = useState(false);
  const [informativePopupskip, setInformativePopupskip] = useState(false);
  const [showFeedBackButton, setShowFeedBackButton] = useState(true);
  const [piExpanded, setPiExpanded] = useState(false);
  const [selectedDrugPI, setSelectedDrugPI] = useState(null)

  const {
    autoSuggest,
    search: {
      maint_msg_title,
      maint_msg_desc,
      under_maint_image,
      under_maint_image_Alt,
      looking_for_txt,
      type_a_txt,
      search_query_img,
      feedback_icon,
      close_icon,
      tooltipContent,
      search_query_alt_txt
    },
    feedback,
    mirpopupResource,
    piDetailsResources,
    noPiResults,
    autoSuggest: {
      error_message_search_term
    },
    srdDocumentDetails
  } = searchResources;
  const {
    getSearchResult,
    searchInput,
    manufacturerDetails,
    getManufacturerDetails,
    tourStatus,
    sectionNameUsingDCode,
    getSectionNameUsingDataCode,
    usersearchQuery,
    setSearchQuery,
    searchResult,
    isLoading,
    isPiLoading,
    piDetails
  } = props;

  const informativePopupTimeout = useRef(null);

  useEffect(() => {
    fetchManufacturerDetails();
    let selectedDegree = localStorage.getItem("selectedDegree");
    if (!selectedDegree) {
      selectedDegree = sessionStorage.getItem("selectedDegree");
      if (selectedDegree) {
        localStorage.setItem("selectedDegree", selectedDegree);
      }
    } else {
      sessionStorage.setItem("selectedDegree", selectedDegree);
    }
    let currentExpert = localStorage.getItem("expert");
    if (!currentExpert) {
      currentExpert = sessionStorage.getItem("expert");
      if (currentExpert) {
        localStorage.setItem("expert", currentExpert);
      }
    }
    if (currentExpert && JSON.parse(currentExpert)) {
      const cExpert = JSON.parse(currentExpert);
      if (cExpert.expert) {
        props.unSetExpert();
      } else {
        props.setExpert();
      }
    }
    selectedDegree = JSON.parse(selectedDegree);
    if (
      searchInput.userInput &&
      !selectedDegree &&
      !currentExpert &&
      searchInput.userInput.userSearchInput
    ) {
      setIsFirstTime(true);
    }
    if (
      searchInput &&
      searchInput.userInput &&
      searchInput.userInput.latestSearchData
    ) {
      setSearchData(searchInput.userInput.latestSearchData);
    }
    currentExpert = JSON.parse(currentExpert)
    if (selectedDegree || currentExpert) {
      if (tourStatus) {
        setRole(selectedDegree.role);
        setRoleDescription(selectedDegree.roleDescription)
      }
      else {
        const patientRole = selectedDegree ? (selectedDegree.role.toLowerCase().includes("patient") ||
      selectedDegree.role.toLowerCase().includes("caregiver") || selectedDegree.role.toLowerCase().includes("consumer")) : ""
        setRole(selectedDegree ? patientRole ? "NE" : selectedDegree.role : currentExpert ? "NE" : "");
        setRoleDescription(selectedDegree ? selectedDegree.roleDescription : "Patient/Caregiver/Consumer");
        setIsFirstTime(false)
      }
    }

    if (
      searchInput &&
      searchInput.userInput &&
      (selectedDegree || currentExpert) &&
      searchInput.userInput.userSearchInput
    ) {
      let searchQuery = "";
      let searchTerm = "";
      searchQuery = searchInput.userInput.userSearchInput.trim();
      if (searchInput.userInput.keywordInput) {
        searchTerm = searchInput.userInput.keywordInput;
      }
      handleSearch(searchQuery, searchTerm, selectedDegree, false);
    }
    // window.addEventListener("resize", resizeHandler);
    // window.addEventListener("scroll", onPageScroll);
    if (_isEmpty(sectionNameUsingDCode)) {
      getSectionNameUsingDataCode();
    }
    const url = new URLSearchParams(props.location.search);
    const query = url.get("query");
    const searchTerm = url.get("searchTerm");
    if (query && query.length > 0) {
      setJaanpRedirect(true)
      const randomOprxId = Math.floor(Math.random() * 1000000);
      localStorage.setItem("adsId", randomOprxId);
      sessionStorage.setItem("adsId", randomOprxId);
      try {
        if (searchTerm && searchTerm.length > 0) {
          if (selectedDegree || currentExpert) {
            setProductTerm(query.trim());
            setSearchTerm(searchTerm.trim());
            const searchQuery = query.trim();
            handleSearch(searchQuery, searchTerm, selectedDegree, true);
          }
          else {
            setProductTerm(query.trim());
            setSearchTerm(searchTerm.trim());
            setIsFirstTime(true)
          }
        }
        else {
          if (selectedDegree || currentExpert) {
            setProductTerm(query.trim());
          }
          else {
            setProductTerm(query.trim());
            setIsFirstTime(true)
          }
        }
        setAdsId(randomOprxId);
        setFromOprx(true)
      } catch (e) {
        //Do Nothing
      }
    }

    return () => {
      const { setSearchData, setSearchResult, emptyPiDetails, clearSearchQuery } = props;
      setSearchData();
      setSearchResult();
      emptyPiDetails();
      clearSearchQuery();
      // window.removeEventListener("resize", this.resizeHandler);
      // window.removeEventListener("scroll", this.onPageScroll);
    }
  }, []);

  const scrollParentToTop = (scroll) => {
    if (scroll && showWebSrdPage) {
      window.scrollTo(0, 0)
    }
  }


  const showInformativePopups = (piExpanded) => {
    setPiExpanded(piExpanded)
  }

  useEffect(() => {
    const firstVisitSearch = localStorage.getItem("firstVisitSearch");
    const firstVisitPIMobile = localStorage.getItem("firstVisitPIMobile");

    if (window.innerWidth < 768) {
      if (searchResult && !informativePopupRun && !searchResult.underMaintenanceFlag) {
        if (!piExpanded && !firstVisitSearch && searchResult.searchResults.length > 0) {
          informativePopupSteps = [
            {
              content: <InformativePopups stepNumber={1} totalSteps={1}>Click to view the Drug Information Response about a specific product.</InformativePopups>,
              locale: { skip: <span aria-label="skip">Later</span>,
                next: <strong aria-label="next">Next</strong>,
                last: <strong aria-label="last">Got it</strong>},
              placement: "left",
              target: "#viewDocument-btn-srd",
              disableBeacon: true,
              floaterProps: {
                disableAnimation: true
              },
              styles: {
                options: {
                  width: 390
                }
              }
            }
          ]
          informativePopupTimeout.current = setTimeout(() => {
            setInformativePopupRun(true);
          }, 3000);
        } else if (piExpanded && !firstVisitPIMobile && searchResult.spl.length > 0) {
          informativePopupSteps = [{
            content: <InformativePopups stepNumber={1} totalSteps={1}>Click to view Full Prescribing Information about a specific product.</InformativePopups>,
            locale: { skip: <span aria-label="skip">Later</span>,
              next: <strong aria-label="next">Next</strong>,
              last: <strong aria-label="last">Got it</strong>},
            placement: "left",
            target: "#viewDocument-btn-pi",
            disableBeacon: true,
            floaterProps: {
              disableAnimation: true
            },
            styles: {
              options: {
                width: 390
              }
            }
          }]
          informativePopupTimeout.current = setTimeout(() => {
            setInformativePopupRun(true);
          }, 3000);
        }
      }
    } else {
      if (role == "NE" && !firstVisitSearch && !informativePopupRun && searchResult && !searchResult.underMaintenanceFlag ) {
        informativePopupSteps = [
          {
            content: <InformativePopups stepNumber={1} totalSteps={1}>Click to view Full Prescribing Information about a specific product.</InformativePopups>,
            locale: { skip: <span aria-label="skip">Later</span>,
              next: <strong aria-label="next">Next</strong>,
              last: <strong aria-label="last">Got it</strong>},
            placement: "left",
            target: "#viewDocument-btn-pi",
            disableBeacon: true,
            floaterProps: {
              disableAnimation: true
            },
            styles: {
              options: {
                width: 390
              }
            }
          }
        ]
        informativePopupTimeout.current = setTimeout(() => {
          setInformativePopupRun(true);
        }, 3000);
      } else if (searchResult && !searchResult.underMaintenanceFlag &&
        searchResult.searchResults.length > 0 && searchResult.spl.length > 0 && !firstVisitSearch && !informativePopupRun) {
        informativePopupTimeout.current = setTimeout(() => {
          setInformativePopupRun(true);
        }, 3000);
      } else if ((searchResult && !searchResult.underMaintenanceFlag && searchResult.searchResults.length === 0 && !firstVisitSearch && !informativePopupRun )) {
        informativePopupSteps = [
          {
            content: <InformativePopups stepNumber={1} totalSteps={1}>Click to view Full Prescribing Information about a specific product.</InformativePopups>,
            locale: { skip: <span aria-label="skip">Later</span>,
              next: <strong aria-label="next">Next</strong>,
              last: <strong aria-label="last">Got it</strong>},
            placement: "left",
            target: "#viewDocument-btn-pi",
            disableBeacon: true,
            floaterProps: {
              disableAnimation: true
            },
            styles: {
              options: {
                width: 390
              }
            }
          }
        ]
        informativePopupTimeout.current = setTimeout(() => {
          setInformativePopupRun(true);
        }, 3000);
      } else if (searchResult && searchResult.spl && searchResult.spl.length === 0 && !firstVisitSearch && !informativePopupRun) {
        informativePopupSteps = [
          {
            content: <InformativePopups stepNumber={1} totalSteps={1}>Click to view the Drug Information Response about a specific product.</InformativePopups>,
            locale: { skip: <span aria-label="skip">Later</span>,
              next: <strong aria-label="next">Next</strong>,
              last: <strong aria-label="last">Got it</strong>},
            placement: "left",
            target: "#viewDocument-btn-srd",
            disableBeacon: true,
            floaterProps: {
              disableAnimation: true
            },
            styles: {
              options: {
                width: 390
              }
            }
          }
        ]
        informativePopupTimeout.current = setTimeout(() => {
          setInformativePopupRun(true);
        }, 3000);
      }
    }
  }, [searchResult, piExpanded])

  const fetchManufacturerDetails = async () => {
    if (!manufacturerDetails) {
      await getManufacturerDetails();
    }
  }
  const searchDataUpdate = (val) => {
    setSearchData([...val]);
    if (val && val.length > 0) {
      setSearchError(false);
    }
  };

  const getSessionId = () => {
    let sessionId = localStorage.getItem("_sid");
    if (!sessionId) {
      sessionId = sessionStorage.getItem("_sid");
      if (!sessionId) {
        sessionId = "NA";
      }
    }
    return sessionId;
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0 });
  };

  const addVendorTag = () => {
    const script = document.createElement("script");
    const script2 = document.createElement("script");
    script.type = "text/javascript";
    script.id = "lassosegment";
    script.src = "https://res.lassomarketing.io/scripts/lassotag.js";
    script2.async = true;
    script2.id = "lasotagConv";
    script2.type = "text/javascript";
    script2.src = "https://trc.lhmos.com/conv?id=4859&type=IMG&enc=true";
    if (document.getElementById("lassosegment")) {
      document.getElementById("lassosegment").remove();
    }
    if (document.getElementById("lasotagConv")) {
      document.getElementById("lasotagConv").remove();
    }
    document.head.appendChild(script);
    document.head.appendChild(script2);
  };

  const handleSearch = (searchQuery, termValue, selectedDegree, fromOprx) => {
    const { setSearchQuery, getSearchResult } = props;
    let degree = localStorage.getItem("selectedDegree");
    if (!degree) {
      degree = sessionStorage.getItem("selectedDegree");
      if (degree) {
        localStorage.setItem("selectedDegree", degree);
      }
    }

    const currentExpert = JSON.parse(localStorage.getItem("expert"));
    const patientRole = selectedDegree ? (selectedDegree.role.toLowerCase().includes("patient") ||
    selectedDegree.role.toLowerCase().includes("caregiver") || selectedDegree.role.toLowerCase().includes("consumer")) : "";

    if (searchQuery.trim() && searchQuery.length > 0) {
      const sessionId = getSessionId();
      getSearchResult({
        userInput: searchQuery.trim(),
        termInput: termValue,
        from: 0,
        degree: selectedDegree ? patientRole ? "NE" : selectedDegree.role : currentExpert ? "NE" : "",
        description: selectedDegree ? selectedDegree.roleDescription : "Patient/Caregiver/Consumer",
        sessionId: sessionId,
        aId: adsId ? adsId : "",
        fromOprx,
        fromAds: false,
        fromHcpPopup: false
      });
      // sendGtag();
      setSearchQuery(searchQuery.trim());
    }
  };

  const onSearchClick = (val, latestSearchData, keywordInput) => {
    setJaanpRedirect(false);
    const { expert, usersearchQuery, setSearchQuery } = props;
    // const { roleDescription, fromOprx, adsId, activeTabIndex } = this.state;
    let selectedDegree = localStorage.getItem("selectedDegree");
    let searchQuery = "";
    let termValue = "";
    let searchString = "";
    latestSearchData.map((element) => {
      searchQuery = searchQuery + (searchQuery.length > 0 ? "|" : "") + element;
      searchString =
        searchString + (searchString.length > 0 ? "|" : "") + element;
    });
    if (fromOprx) {
      setFromOprx(false);
    }
    if (keywordInput && keywordInput.length > 0) {
      searchQuery =
        searchQuery + (searchQuery.length > 0 ? " " : "") + keywordInput;
      termValue = keywordInput;
    }
    if (!selectedDegree) {
      selectedDegree = sessionStorage.getItem("selectedDegree");
      if (selectedDegree) {
        localStorage.setItem("selectedDegree", selectedDegree);
      }
    }
    selectedDegree = JSON.parse(selectedDegree);
    if (!selectedDegree && expert) {
      setIsFirstTime(true);
    }
    if (usersearchQuery !== searchQuery.trim()) {
      // this.sendGtag();
      const sessionId = getSessionId();
      props.getSearchResult({
        userInput: searchString.trim(),
        termInput: termValue,
        from: 0,
        degree: role,
        description: roleDescription,
        sessionId: sessionId,
        aId: adsId ? adsId : "",
        fromOprx: false,
        fromHcpPopup: false
      });

      // this.setState({
      //   currentPage: 1,
      //   srd_records_per_page: 0,
      //   resizeMainCont: activeTabIndex === 0 ? true : false
      // });
      setSearchQuery(searchQuery.trim());
    }
    document.getElementById("auto-suggest-search").focus();
    scrollToTop();
    // this.setState({
    //   companyID: []
    // });
    //This is added to track the search click
    const imagePixel = document.createElement("img");
    imagePixel.setAttribute(
      "src",
      "https://ct.ardrone.swoop.com/ct.gif?tracker_id=02c997d6-2bdb-42f2-943e-853f219d1855"
    );
    document.body.appendChild(imagePixel);
    addVendorTag();
  };

  const searchPopUpCalled = () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "Search Button Click Event",
      phactmiEventData: {
        "eventCategory": "Search Funnel",
        "eventAction": "Search Button"
      }
    });
  }

  const onSubmitHandle = (val, selectedrole, selectedroleDescription) => {
    const { setFirstSearch, setExpert, getSearchResult, searchInput, setSearchQuery, usersearchQuery } = props;
    setIsFirstTime(false)
    const sessionId = getSessionId();
    window.dataLayer = window.dataLayer || [];
    setFirstSearch();
    let searchQuery = "";
    let searchString = "";
    let termValue = "";
    searchData.map(element => {
      searchQuery = searchQuery + (searchQuery.length > 0 ? " " : "") + element;
      searchString = searchString + (searchString.length > 0 ? "|" : "") + element;
    })
    searchQuery.trim();
    if (window.innerWidth >= 768) {
      if (document.querySelector(".keywordSearch")) {
        const keywordValue = document.querySelector(".keywordSearch").value;
        searchQuery = searchQuery + (searchQuery.length > 0 ? " " : "") + keywordValue;
        termValue = keywordValue;
      }
    }
    else {
      if (document.querySelector(".keywordSearchMobile")) {
        const keywordValue = document.querySelector(".keywordSearchMobile").value;
        searchQuery = searchQuery + (searchQuery.length > 0 ? " " : "") + keywordValue;
        termValue = keywordValue;
      }
    }
    if (!val) {
      setExpert();
      let expert = "";
      expert = {
        expert: false
      }
      localStorage.setItem("expert", JSON.stringify(expert));
      sessionStorage.setItem("expert", JSON.stringify(expert));
      window.dataLayer.push({ submit: null });
      window.dataLayer.push({
        event: "Yes No Filter",
        phactmiEventData: {
          eventCategory: "Degree Seperator",
          eventLabel: "No"
        }
      });

      const url = new URLSearchParams(props.location.search);
      const redirectSource = url.get("utm_source");
      setSearchQuery(searchQuery.trim());
      getSearchResult({
        "userInput": searchString.trim(),
        "termInput": termValue,
        from: 0,
        degree: "NE",
        description: "Patient/Caregiver/Consumer",
        sessionId: sessionId,
        aId: adsId ? adsId : "",
        fromOprx,
        fromAds: !redirectSource ? false : true,
        fromHcpPopup: true
      });
      setRole("NE");
      setRoleDescription("Patient/Caregiver/Consumer")
    }
    else {
      const url = new URLSearchParams(props.location.search);
      const redirectSource = url.get("utm_source");
      //sendGtag();
      setSearchQuery(searchQuery.trim());
      getSearchResult({
        "userInput": searchString.trim(),
        "termInput": termValue,
        from: 0,
        degree: selectedrole,
        description: selectedroleDescription,
        sessionId: sessionId,
        aId: adsId ? adsId : "",
        fromOprx,
        fromAds: !redirectSource ? false : true,
        fromHcpPopup: true
      });
      setRole(selectedrole)
      setRoleDescription(selectedroleDescription)
      window.dataLayer.push({ submit: null });
      window.dataLayer.push({
        event: "Yes No Filter",
        phactmiEventData: {
          eventCategory: "Degree Seperator",
          eventLabel: selectedrole
        }
      });
    }
    scrollToTop();
    // this.setState({
    //   companyId: [],
    //   srd_records_per_page: 0
    // })
  }

  const showPiDetails = (item) => {
    const degree = JSON.parse(localStorage.getItem("selectedDegree"));
    let roleDegree;
    let roleDegreeDescription;
    if (degree) {
      const { role, roleDescription} = degree
      roleDegree = role;
      roleDegreeDescription = roleDescription
    } else {
      roleDegree = "NE"
      roleDegreeDescription = ""
    }
    setIsPiLoaded(true);
    setDisplayMirPopup(true);
    setConfirmedNavigation(false)
    const sessionId = getSessionId();
    props.getPIDetails(item.setId, roleDegree, roleDegreeDescription, sessionId, item.version);
  }

  const fetchJanseenUrl = async (id, query, term, sourceDisplayName, sourceId) => {
    const sessionId = getSessionId();
    if (!id || !query || !term || !sourceDisplayName) {
      return;
    }

    try {
      await axios.post(`${endpoint}/api/search/substances/${id}/metadata?query=${query}&term=${term}&sessionId=${sessionId}&sourceDisplayName=${sourceDisplayName}`, {
        docId:id,
        action:"document",
        application: "web-browser",
        url:`${endpoint}${props.location.pathname}${props.location.search}`,
        product:query.trim(),
        searchTerm: term.trim(),
        sourceDisplayName,
        sessionId,
        sourceId
      })
        .then(({data}) => {
          if (data.url) {
            window.open(`/redirect?url=${data.url}`, "_blank");
          }
        })
    }
    catch (error) {
      //Do not open in case of error
    }
  }

  const renderSearchQueryMessage = (search_query_img, search_query_alt_txt, looking_for_txt, type_a_txt) => (
    <styled.LookingWrapper>
      <div>
        <img src={search_query_img} alt={search_query_alt_txt} />
      </div>
      <styled.LookingPara>{looking_for_txt}</styled.LookingPara>
      <styled.TypePara>{type_a_txt}</styled.TypePara>
    </styled.LookingWrapper>
  );

  const renderSearchMaintMessage = (search_query_img, search_query_alt_txt, looking_for_txt, type_a_txt) => (
    <styled.LookingWrapperMaint className="under_maintenance">
      <div>
        <img src={search_query_img} alt={search_query_alt_txt} />
      </div>
      <styled.LookingPara underMaintenance={true}>{looking_for_txt}</styled.LookingPara>
      <styled.TypePara underMaintenance={true}>{type_a_txt}</styled.TypePara>
    </styled.LookingWrapperMaint>
  );

  const handleBlockedNavigation = nextLocation => {
    if (
      !confirmedNavigation && nextLocation.pathname.includes("search")
    ) {
      showModal(nextLocation);
      return false;
    }
    return true;
  };

  const showModal = location => {
    setModalVisible(true);
    setLastLocation(location);
  }

  const closeModal = (callback = () => null) => {
    if (document.querySelector(".overlay")) {
      document.querySelector(".overlay").style.display = "none";
    }
    setModalVisible(false);
    callback();
  }

  const handleConfirmNavigationClick = () =>
    closeModal(() => {
      if (document.querySelector(".overlay")) {
        document.querySelector(".overlay").style.display = "none";
      }
      setConfirmedNavigation(true);
      showSearchDetails();
    });

  const displayPopUp = () => {
    const { before_yo_go_txt, description, continue_txt, cancel_txt, popup_image } = searchResources.mirConfirmationPopup;
    window.scrollTo(0, 0)
    if (document.querySelector(".overlay")) {
      document.querySelector(".overlay").style.display = "block";
    }
    return (
      <styled.PopupWrapper>
        <img src={popup_image} alt="popup_img"></img>
        <p>
          {before_yo_go_txt}
        </p>
        <p className="leavingMessage">
          {description}
        </p>
        <styled.PopUpButtonWrapper>
          <styled.PopUpButton changeBg={true} onClick={handleConfirmNavigationClick}>{continue_txt}</styled.PopUpButton>
          <styled.PopUpButton changeBg={false} onClick={() => closeModal(() => null)}>{cancel_txt}</styled.PopUpButton>
        </styled.PopUpButtonWrapper>
      </styled.PopupWrapper>
    )
  }

  const openSrdDocument = (searchItem) => {
    const { id, sourceDisplayName} = searchItem;
    let query = "";
    if (searchData && searchData.length > 0) {
      searchData.map((ele) => {
        query = query.length > 0 ? query + "|" + ele : ele;
      })
    }
    let keywordInput = "";
    if (window.innerWidth >= 768) {
      if (document.querySelector(".keywordSearch")) {
        keywordInput = document.querySelector(".keywordSearch").value;
      }
    }
    else {
      if (document.querySelector(".keywordSearchMobile")) {
        keywordInput = document.querySelector(".keywordSearchMobile").value;
      }
    }
    const { searchInput } = props;
    const { userInput: {userSearchInput, keywordInput: searchTerm} } = searchInput;
    //Check for product name of janssen
    if (searchItem && searchItem.sourceDisplayName && searchItem.sourceDisplayName.toLowerCase().includes("johnson & johnson")) {
      fetchJanseenUrl(id, userSearchInput, searchTerm, sourceDisplayName, searchItem.sourceId);
    }
    else {
      let encodedTerm;
      if (searchTerm.includes("%")) {
        encodedTerm = searchTerm.replaceAll("%", encodeURIComponent("%"));
      } else {
        encodedTerm = searchTerm;
      }
      const url = `${endpoint}/viewer?id=${id}&query=${userSearchInput}&term=${encodedTerm}&sourceDisplayName=${sourceDisplayName}`;
      window.open(url, "_blank");
    }

  }

  const openWebSrdDoc = (srdItemDetails) => {
    const baseUrl = srdConverterUrl;
    const jsonUrl = srdItemDetails.linkToJson;
    const linkToPdfDownload = srdItemDetails.linkToPdf;
    const souceName = srdItemDetails.sourceDisplayName;
    const products = srdItemDetails && srdItemDetails.products && srdItemDetails.products.join(", ");
    const {title} = srdItemDetails;
    const sessionId = getSessionId();
    const { searchInput } = props;
    const { userInput: {userSearchInput, keywordInput} } = searchInput;

    axios
      .post(`${endpoint}/api/search/substances/summaries/${srdItemDetails.id}`, {
        docId: srdItemDetails.id,
        action: "document",
        application: "web-browser",
        product: userSearchInput.trim(),
        searchTerm: keywordInput.trim(),
        url:`${endpoint}${props.location.pathname}`,
        sourceDisplayName: srdItemDetails.sourceDisplayName,
        sessionId
      }).then(data => {
        return data
      }).catch(error => {
        throw error
      })

    const link = `${baseUrl}?urlForJson=${jsonUrl}&urlForPdf=${linkToPdfDownload}&sourceDocumentId=${srdItemDetails.sourceId}&souceName=${souceName}&products=${products}&title=${title}`;
    setWebSrdItemDetails(srdItemDetails);
    setUrlForWebSrdIFrame(link);
    setShowWebSrdPage(true);
    props.showSrdPage(true)
  }

  const showSearchDetails = () => {
    window.scrollTo(0, 0);
    props.emptyPiDetails();
  }

  const searchFunctionality = () => {
    props.showSrdPage(false)
    setShowWebSrdPage(false);
    setUrlForWebSrdIFrame("");
    setWebSrdItemDetails(null);
  }

  const handleJoyrideCallback = (data) => {
    const { index, status, size } = data;
    if (index !== 0 ) {
      const previousButton = document.querySelector("[title='Previous']");
      if (previousButton) {
        previousButton.parentNode.style.justifyContent = "space-between";
        previousButton.style.marginLeft = "0";
        previousButton.parentNode.firstChild.style.display = "none"
      }
    }

    if (status === "skipped") {
      setInformativePopupRun(false);
      setInformativePopupskip(true)
    } else if (window.innerWidth >= 768 && status === "finished" ) {
      clearTimeout(informativePopupTimeout.current)
      setInformativePopupRun(false);
      const firstVisitSearch = {
        firstVisitSearch: true
      }
      localStorage.setItem("firstVisitSearch", JSON.stringify(firstVisitSearch));
    } else if (window.innerWidth < 768) {
      if (status === "finished" && piExpanded) {
        clearTimeout(informativePopupTimeout.current)
        setInformativePopupRun(false);
        const firstVisitPIMobile = {
          isFirstVisit: true
        }
        localStorage.setItem("firstVisitPIMobile", JSON.stringify(firstVisitPIMobile));
      } else if (status === "finished" && !piExpanded) {
        clearTimeout(informativePopupTimeout.current)
        setInformativePopupRun(false);
        const firstVisitSearch = {
          firstVisitSearch: true
        }
        localStorage.setItem("firstVisitSearch", JSON.stringify(firstVisitSearch));
      }
    }
  }


  const viewFullDocumentClick = (isSrdDoc, searchItem, genericname) => {
    setSelectedProduct(searchItem);
    if (searchItem.linkToJson && searchItem.linkToJson !== "") {
      openWebSrdDoc(searchItem);
    }
    else {
      const degree = JSON.parse(localStorage.getItem("selectedDegree"));
      let degreeRole;
      if (degree) {
        degreeRole = degree.role;
      } else {
        degreeRole = "NE"
      }

      if (degreeRole.toLowerCase().includes("patient") || degreeRole.toLowerCase().includes("consumer") || degreeRole.toLowerCase().includes("caregiver")) {
        degreeRole = "PT"
      }

      window.dataLayer = window.dataLayer || [];
      const { googleAnalytics: { piviewfulldoc_txt, srdviewfulldoc_txt } } = searchResources;
      if (isSrdDoc) {
        openSrdDocument(searchItem);
        if (GADegrees.includes(degreeRole)) {
          window.dataLayer.push({
            event: "PhactMI Custom Event",
            phactmiEventData: {
              "eventCategory": degreeRole,
              "eventAction": srdviewfulldoc_txt,
              "eventLabel": searchItem.title,
              "cd1": degreeRole,
              "cd2": srdviewfulldoc_txt,
              "cd3": searchItem.title,
              "cd4": usersearchQuery,
              "cd5": searchItem.sourceDisplayName,
              "cd8": "Null"
            }
          })
        }
        else {
          window.dataLayer.push({
            event: "PhactMI Custom Event",
            phactmiEventData: {
              "eventCategory": degreeRole,
              "eventAction": srdviewfulldoc_txt,
              "eventLabel": searchItem.title,
              "cd1": "Other",
              "cd2": srdviewfulldoc_txt,
              "cd3": searchItem.title,
              "cd4": usersearchQuery,
              "cd5": searchItem.sourceDisplayName
            }
          })
        }

      } else {
        if (GADegrees.includes(degreeRole)) {
          window.dataLayer.push({
            event: "PhactMI Custom Event",
            phactmiEventData: {
              "eventCategory": degreeRole,
              "eventAction": piviewfulldoc_txt,
              "eventLabel": searchItem.drug,
              "cd1": degreeRole,
              "cd2": piviewfulldoc_txt,
              "cd3": searchItem.drug,
              "cd4": usersearchQuery,
              "cd5": searchItem.producer,
              "cd8": "Null"
            }
          })
        }
        else {
          window.dataLayer.push({
            event: "PhactMI Custom Event",
            phactmiEventData: {
              "eventCategory": degreeRole,
              "eventAction": piviewfulldoc_txt,
              "eventLabel": searchItem.drug,
              "cd1": "Other",
              "cd2": piviewfulldoc_txt,
              "cd3": searchItem.drug,
              "cd4": usersearchQuery,
              "cd5": searchItem.producer,
              "cd8": degreeRole
            }
          })
        }
        const currentItem = searchItem;
        currentItem.genericName = genericname.toLowerCase();
        showPiDetails(currentItem);
      }
    }
  }

  const onSourceClick = (companyId) => {
    if (companyId && companyId !== "NA") {
      props.history.push({
        pathname: `/members/${companyId}`,
        state: {
          _id: companyId,
          _from: "search"
        }
      });
    }
    else {
      props.history.push({
        pathname: "/members",
        state: {
          _id: companyId,
          _from: "search"
        }
      });
    }
  }

  const pillCloseClick = () => {
    const { usersearchQuery, setSearchQuery, setSearchResult } = props;
    let keywordInput = "";
    if (window.innerWidth >= 768) {
      if (document.querySelector(".keywordSearch")) {
        keywordInput = document.querySelector(".keywordSearch").value;
      }
    }
    else {
      if (document.querySelector(".keywordSearchMobile")) {
        keywordInput = document.querySelector(".keywordSearchMobile").value;
      }
    }
    let currentQuery = "";
    searchData.map((element) => {
      currentQuery = currentQuery + (currentQuery.length > 0 ? " " : "") + element;
    })
    currentQuery = currentQuery + (currentQuery.length > 0 ? " " : "") + keywordInput;
    if (currentQuery !== usersearchQuery) {
      setSearchQuery(currentQuery);
    }
    if (searchData.length > 0 && keywordInput.length > 0) {
      onSearchClick("", searchData, keywordInput);
    }
    else if (searchData.length === 0 && keywordInput.length > 0) {
      setSearchResult()
      setTermError(false);
    }
    else if (searchData.length === 0 && keywordInput.length === 0) {
      setSearchResult()
      setTermError(false)
    }
    else {
      setSearchResult();
    }
  }

  return (
    <styled.SearchPage>
      {!showWebSrdPage && isPiLoading ? <PiLoaderComponent /> :
        !showWebSrdPage && piDetails !== null && !isPiLoading && <PiDetails htmlContent={piDetails}
          item={selectedProduct}
          resources={piDetailsResources}
          showSearchDetails={showSearchDetails}
          noPiResultResources={noPiResults}
          fromSearchPage={true}
          isError = {props.error}
          usersearchQuery={props.usersearchQuery}
          checkPiComponentLoaded={(piLoaded) => setIsPiLoaded(piLoaded)}
        />
      }
      {showWebSrdPage && <SrdPage resources={srdDocumentDetails} urlForSrdIFrame={urlForWebSrdIFrame} manufacturerDetails={manufacturerDetails.data}
        SearchFunctionality={searchFunctionality} srdItemDetails={webSrdItemDetails} scrollParentToTop={scrollParentToTop}/>}
      { !showWebSrdPage && !piDetails && !isPiLoading && <section>
        <styled.SearchBg></styled.SearchBg>
        <styled.Container>
          <styled.SearchContainerCard>
            <styled.SearchWrapper>
              <styled.SearchContainer>
                <AutoSuggestSearch
                  resources={autoSuggest}
                  value={""}
                  onSearchClick={(val, latestSearchData, keywordInput) =>
                    onSearchClick(val, latestSearchData, keywordInput)
                  }
                  fromHomePage={false}
                  searchDataUpdate={(val) => searchDataUpdate(val)}
                  searchQueryData={searchData}
                  checkError={(val) => setSearchError(val)}
                  keywordInput={
                    searchInput &&
                    searchInput.userInput &&
                    searchInput.userInput.keywordInput
                      ? searchInput.userInput.keywordInput
                      : ""
                  }
                  pillCloseClick={(val) => pillCloseClick(val)}
                  fromSearchPage={true}
                  productTerm={productTerm}
                  searchTerm={searchTerm}
                  checkTermError={(val) => setTermError(val)}
                  error_message_search_term={error_message_search_term}
                  showJaanpError={
                    jaanpRedirect &&
                    searchResult &&
                    searchResult.spl &&
                    searchResult.spl.length === 0 &&
                    searchResult.searchResults &&
                    searchResult.searchResults.length === 0
                  }
                />
              </styled.SearchContainer>
              <styled.TooltipSection searchError={searchError}>
                <styled.HelpToolTipSection>
                  <HelpToolTip resources={tooltipResources} history={props.history} searchError={searchError}></HelpToolTip>
                </styled.HelpToolTipSection>
              </styled.TooltipSection>
            </styled.SearchWrapper>
            {<div>
              {( isFirstTime || isLoading ) && <SearchLoader firstsearch={isFirstTime} />}
              {!isFirstTime && !isLoading && !searchResult
                          && renderSearchQueryMessage(search_query_img, search_query_alt_txt, looking_for_txt, type_a_txt)}
              { !isFirstTime && !isLoading && searchResult && searchResult.underMaintenanceFlag
                          && renderSearchMaintMessage(under_maint_image, under_maint_image_Alt, maint_msg_title, maint_msg_desc)}
              {!isFirstTime && !isLoading && searchResult && !searchResult.underMaintenanceFlag && <styled.SearchResultsContainer>
                { <styled.MemberContactInfoContainer><MemberContactInfo searchInput={searchInput}
                  zeroSearchResultMemberDetails={searchResult.zeroSearchResultMemberDetails}
                  expanded={searchResult.searchResults.length === 0 && searchResult.spl.length === 0} /></styled.MemberContactInfoContainer>}
                {searchResult.spl.length !== 0 &&
                <styled.HorizonalDividerMobile></styled.HorizonalDividerMobile>}
                {<styled.PIContainer mobileHide={searchResult.spl.length === 0}>
                  <PIResults piResults={searchResult.spl} onViewFullDocumentClick={viewFullDocumentClick} searchInput={searchInput} setSelectedDrugPI={setSelectedDrugPI}
                    role={role} usersearchQuery={usersearchQuery} manufacturerDetails={manufacturerDetails} selectedDrugPI={selectedDrugPI}
                    onSourceClick={onSourceClick} expanded={searchResult.searchResults.length === 0} showInformativePopups={showInformativePopups} /></styled.PIContainer>}
                {<styled.VerticalDividerMobile></styled.VerticalDividerMobile>}
                {searchResult.searchResults.length !== 0 && <styled.HorizonalDividerMobile></styled.HorizonalDividerMobile>}
                {
                  <styled.SRDsContainer mobileHide={searchResult.searchResults.length === 0 && role !== "NE"}>
                    <SearchSRDResults srds={searchResult.searchResults} piResults={searchResult.spl} manufacturerDetails={manufacturerDetails}
                      onViewFullDocumentClick={viewFullDocumentClick} srdFlag={searchResult.srdFlag} role={role}
                      usersearchQuery={usersearchQuery} subscriptionMessage={props.subscriptionMessage}
                      zeroSearchResultMemberDetails={searchResult.zeroSearchResultMemberDetails}
                      searchInput={searchInput}onSourceClick={onSourceClick} expanded={true} /></styled.SRDsContainer>}
                {searchResult.searchResults.length === 0 && searchResult.spl.length === 0 &&
                <styled.NoPiSrdMobileAlert> <img src={AlertIconGreen} alt="No PI & SRDs" />There is no PI available</styled.NoPiSrdMobileAlert>}
              </styled.SearchResultsContainer>}
            </div>}
          </styled.SearchContainerCard>
        </styled.Container>
        <styled.HelpAndFeedbackSection isPiLoaded={isPiLoaded} isLoading={isLoading} className="helpandfeedback">
          {!isFirstTime && props.expert && !isLoading && searchResult && !searchResult.underMaintenanceFlag && searchResult.spl.length > 0 &&
              <div className="feedback">
                <DidItHelpSection feedbackConstants={feedback} searchQuery={searchInput ? searchInput.userInput ? searchInput.userInput : "" : ""}
                  degree={role} />
              </div>
          }
          {!isFirstTime && props.expert && !isLoading && searchResult && !searchResult.underMaintenanceFlag && searchResult.spl && showFeedBackButton &&
          <FeedbackToolTip feedback_icon={feedback_icon} close_icon={close_icon} tooltipContent={tooltipContent}></FeedbackToolTip>}
        </styled.HelpAndFeedbackSection>
        <styled.HomePopSection>
          {isFirstTime && props.expert && (
            <div className="Hcp_popup">
              <SearchPopUp
                data={mirpopupResource}
                popUpCalled={() => searchPopUpCalled()}
                onSubmitHandle={(val, selectedrole, selectedroleDescription) =>
                  onSubmitHandle(
                    val,
                    selectedrole,
                    selectedroleDescription
                  )
                }
              ></SearchPopUp>
            </div>
          )}
        </styled.HomePopSection>
        {isFirstTime && props.expert && (
          <div className="overlay_image">
            <img src={OverlayImage}></img>
          </div>
        )}
      </section>}
      {/* )} */}
      {
        <Prompt
          when={displayMirPopup}
          message={handleBlockedNavigation}
        ></Prompt>
      }
      {modalVisible && displayPopUp()}
      {/* <Joyride
        disableOverlayClose
        callback={handleJoyrideCallback}
        showSkipButton
        continuous
        disableScrollParentFix={false}
        disableScrolling
        hideCloseButton={false}
        run={informativePopupRun}
        steps={informativePopupSteps}
        styles={{
          options: {
            zIndex: 10000
          }
        }}
      /> */}
    </styled.SearchPage>
  );
};

export default connect(mapStateToProps, actions)(withTransition(NewSearch));