import React, { Component } from "react";
import { connect } from "react-redux";
import { Container } from "react-bootstrap";
import withTransition from "../../components/with-transition";
import { endpoint } from "../../config/app.config";
import * as actions from "./actions";
import * as styled from "./styles";
import resources from "../../config/content/fellowship";
import { Tooltip } from "react-tooltip";

const mapStateToProps = state => {
  return {
    fellowshipData: state.fellowshipReducer.fellowshipData
  };
};

/**
 * Fellowship page container
 * @class Fellowship
 * @extends Component
 * @exports Fellowship
 */
class Fellowship extends Component {

  state = {
    fellowshipdata: null
  }

  componentDidMount() {
    const { fellowshipData, getFellowship } = this.props;
    if (!fellowshipData) {
      getFellowship();
    }
    else {
      this.setState({
        fellowshipdata: fellowshipData
      })
    }
  }

  componentDidUpdate(prevProps) {
    const { fellowshipData } = this.props;
    if (prevProps.fellowshipData !== this.props.fellowshipData) {
      const currentdata = fellowshipData;
      currentdata.map((element) => {
        element["readmore"] = false;
      })
      this.setState({
        fellowshipdata: currentdata
      })
    }
  }

  redirect = (link) => {
        window.open(`/redirect?url=${link}`);
  }

  /**
   * Function to insert html for fellowship and company
   * @returns {HTMLElement} html
   */
  displayContent = () => {
    const { fellowshipdata } = this.state;
    const { banner_image, fellowship_description, search_text, search_logo } = resources;
    if (fellowshipdata) {
      return (
        <styled.ContentWrapper>
          <span>{fellowship_description}</span>
          <styled.SearchButtonWrapper><button data-tooltip-id="search-tooltip" data-tooltip-float={true} data-tooltip-content="https://www.industrypharmacist.org/catalog_fellowship.php" onClick={() => this.redirect("https://www.industrypharmacist.org/catalog_fellowship.php")}>
            <img src={search_logo} alt="Search" />
            <Tooltip id="search-tooltip" offset={-60} noArrow
              style={{ backgroundColor: "#99E1D7",
                color: "#000000" }}
              />
            <span>{search_text}</span></button></styled.SearchButtonWrapper>
            <a data-tooltip-id="image-tooltip" data-tooltip-float={true} data-tooltip-content="https://app.box.com/s/ly1k65sj4kdd210a4lw8nv7hnib1fs0b">
              <img src={banner_image} alt={"fellowship_banner"} onClick={() => this.redirect("https://app.box.com/s/ly1k65sj4kdd210a4lw8nv7hnib1fs0b")}/>
              <styled.MobileBannerLink href="https://app.box.com/s/ly1k65sj4kdd210a4lw8nv7hnib1fs0b">https://app.box.com/s/ly1k65sj4kdd210a4lw8nv7hnib1fs0b</styled.MobileBannerLink>
              <Tooltip id="image-tooltip" offset={-70} noArrow
              style={{ backgroundColor: "#99E1D7",
                color: "#000000" }}
              />
            </a>
        </styled.ContentWrapper>
      )
    }
  }

  /**
   * Function to insert html for fellowship and company for mobile
   * @returns {HTMLElement} html
   */
  displayContentMobile = () => {
    const { fellowshipdata } = this.state;
    const { company_txt, fellowship_txt, desc_txt } = resources;
    if (fellowshipdata) {
      return (
        <styled.ContentWrapperMobile>
          {fellowshipdata.map((element, index) => {
            return (
              <React.Fragment key={index}>
                <styled.TitleWrapperMobile index={index}>
                  <styled.CompanyTitle>
                    {company_txt}
                  </styled.CompanyTitle>
                  <styled.FellowshipTitle>
                    {fellowship_txt}
                  </styled.FellowshipTitle>
                </styled.TitleWrapperMobile>
                <styled.DescriptionWrapperMobile>
                  <styled.ImageWrapperMobile>
                    <img src={`${endpoint}/api/assets/images?id=${element.companyLogo}`} alt="c_logo"></img>
                  </styled.ImageWrapperMobile>
                  <styled.FellowshipDescTitle>
                    {element.fellowshipName}
                  </styled.FellowshipDescTitle>
                </styled.DescriptionWrapperMobile>
                <styled.DescriptionTitle>{desc_txt}</styled.DescriptionTitle>
                {this.displayDescription(element, index)}
              </React.Fragment>
            )
          })}
        </styled.ContentWrapperMobile>
      )
    }
  }

  /**
   * @param {Number} index index
   * @returns {undefined}
   */
  handleReadMoreClick = (index) => {
    const { fellowshipdata } = this.state;
    const currentdata = fellowshipdata;
    currentdata[index].readmore = !currentdata[index].readmore;
    this.setState({
      fellowshipdata: currentdata
    })
  }

  /**
   *
   * @param {Array} data data
   * @param {Number} index index
   * @returns {undefined}
   */
  displayDescription = (data, index) => {
    const { program_web_txt, read_more_txt, read_less_txt } = resources;
    return (
      <styled.DescriptionWrapper>
        <styled.Content dangerouslySetInnerHTML={{
          __html: data.readmore ? data.fellowshipDetailDescription :
            data.fellowshipShortDescription.length > 0 ? data.fellowshipShortDescription : data.fellowshipDetailDescription
        }}>
        </styled.Content>
        {
          data.fellowshipShortDescription.length > 0 ?
            <styled.ReadMoreWrapper onClick={() => this.handleReadMoreClick(index)}>
              {data.readmore ? read_less_txt : read_more_txt}
            </styled.ReadMoreWrapper>
            :
            null
        }
        {
          data.programWebsite &&
          <styled.GoToProgramWrapper>
            <a href={data.programWebsite}>{program_web_txt}</a>
          </styled.GoToProgramWrapper>
        }
      </styled.DescriptionWrapper>
    )
  }

  render() {
    const { title, fellowship_sub_title, fellowship_new_title_1, fellowship_new_title_2 } = resources;
    return (
      <styled.FellowshipPage>
        <Container className="container">
          <styled.HeaderSection dangerouslySetInnerHTML={{ __html: title }}></styled.HeaderSection>
          <styled.MainSection>
            <styled.TitleWrapper><span>All</span>{fellowship_new_title_1}<span>All</span>{fellowship_new_title_2}</styled.TitleWrapper>
            {this.displayContent()}
          </styled.MainSection>
        </Container>
      </styled.FellowshipPage>
    )
  }
}

export default connect(
  mapStateToProps,
  actions
)(withTransition(Fellowship))