import React, { Component, useState, useRef, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import throttle from "lodash/throttle";

import { Worker, Viewer, SpecialZoomLevel, ProgressBar, Icon } from "@react-pdf-viewer/core";
import { searchPlugin } from "@react-pdf-viewer/search";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/search/lib/styles/index.css";
import * as styled from "./style";
import searchResources from "../../config/content/searchPage";

import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

const options = {
  cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
  standardFontDataUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/standard_fonts`
};
pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.mjs`;

function highlightPattern(text, words) {
  // const regex = new RegExp(highlightWords.join("|"), "gi")
  //   const splitText = text.split(regex)


  //   if (splitText.length <= 1) {
  //     return text
  //   }

  //   const matches = text.match(regex)

  //   return splitText.reduce(
  //     (arr, element, index) =>
  //       matches[index]
  //         ? [...arr, element, `<mark>${matches[index]}</mark>`]
  //         : [...arr, element],
  //     []
  //   )
// eslint-disable-next-line no-console
  // console.log(text.replace(word, (value) => `<mark style="background-color: red">${value}</mark>`));

  // let finalString = text;

  // for (let i=0;i<words.length;i++) {
  //   finalString = finalString.replace(words[i], (value) => `<mark>${value}</mark>`)
  // }

  // return finalString;

  const pattern = new RegExp(`(${words.join("|")})`, "gi"); // 'gi' for global and case-insensitive

  return text.replace(pattern, (match) => `<mark>${match}</mark>`);
}

function base64ToBlob(base64) {
  const binaryString = window.atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; ++i) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  return new Blob([bytes], { type: "application/pdf" });
}

const PdfViewer = (props) => {
  const [numPages, setNumPages] = useState();
  const [initialWidth, setInitialWidth] = useState(null);
  const [isDocumentReady, setIsDocumentReady] = useState(false);

  const pdfWrapper = useRef();

  const pdfViewer = useRef();
  const { keywords, base64 } = props;
  const url = URL.createObjectURL(base64ToBlob(base64));
  const searchPluginInstance = searchPlugin({
    keyword: keywords.map((word) => {
      return {
        keyword: word,
        matchCase: true,
        // wholeWords: word.length > 1 ? false : true
        wholeWords: false
      };
    })
  });

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.mjs`;
    window.addEventListener("resize", throttle(setPdfSize, 3000));
    setPdfSize();
    return () => {
      window.removeEventListener("resize", throttle(setPdfSize, 3000));
    };
  }, []);

  const setPdfSize = () => {
    if (pdfWrapper && pdfWrapper.current) {
       // eslint-disable-next-line no-console
      // console.log(pdfWrapper.current.getBoundingClientRect().width)
      if (pdfWrapper.current.getBoundingClientRect().width < 768) {
        setInitialWidth(pdfWrapper.current.getBoundingClientRect().width);
      } else if (pdfWrapper.current.getBoundingClientRect().width < 1024) {
        setInitialWidth(pdfWrapper.current.getBoundingClientRect().width - 200);
      } else if (pdfWrapper.current.getBoundingClientRect().width > 1024) {
        setInitialWidth(pdfWrapper.current.getBoundingClientRect().width - 500);
      }
    }
  };

  const textRenderer = useCallback(
    (textItem) => {
        return highlightPattern(textItem.str, keywords)
    }
    ,
    [keywords]
  );

function onDocumentLoadSuccess({ numPages }) {
  setNumPages(numPages);
  setIsDocumentReady(true)
}

  return (
    <styled.ViewerContainer>
      {/* <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
        <Viewer
          fileUrl={"https://srd-connectors-output.s3.us-west-2.amazonaws.com/vevt/uat/ionis_pharmaceuticals/IONI_VEVT_5201_4152bf7d-7569-4699-a293-42235b762d22.pdf"}
          plugins={[searchPluginInstance]}
          defaultScale={SpecialZoomLevel.PageWidth}
          ref={pdfViewer}
        />
      </Worker> */}
      <div ref={pdfWrapper}>
        <Document key={url} file={url} onLoadSuccess={onDocumentLoadSuccess} options={options}>
        {isDocumentReady && Array.from(
          new Array(numPages),
          (el, index) => (
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              customTextRenderer={textRenderer}
              scale={1}
              renderAnnotationLayer="true"
              renderInteractiveForms="true"
              width={initialWidth}
            />
          )
        )}
        </Document>
      </div>
    </styled.ViewerContainer>
  );
};

PdfViewer.propTypes = {
  base64: PropTypes.string,
  keywords: PropTypes.array
};

export default PdfViewer;