import fellowship_banner from "../../assets/images/fellowship_banner.png";
import Search_icon from "../../assets/images/Search_icon.png";

export default {
  "title": "<b>Fellowships</b>",
  "fellowship_sub_title": "Fellowship Directory",
  "fellowship_new_title_1": " Fellowships, ",
  "fellowship_new_title_2": " in One Place",
  "fellowship_description": "The IPhO Fellowship Catalog is a comprehensive database of all U.S. pharmaceutical industry fellowships, updated daily during the recruitment season. Browse and search fellowships by company, affiliation, functional area, and location using a FREE IPhO website account. Access direct website links, application information, program brochures, and more!",
  "company_txt": "Company",
  "fellowship_txt": "Fellowship",
  "desc_txt": "Description",
  "program_web_txt": "Go to Program Website",
  "read_more_txt": "Read More",
  "read_less_txt": "Read Less",
  "banner_image": fellowship_banner,
  "search_logo": Search_icon,
  "search_text": "SEARCH THE CATALOG"
}