import styled from "styled-components";
import { colors, containerMaxWidth } from "../../common/styles/variables";
import { mobileRange, tabletRange, ipadProRange } from "../../common/styles/breakpoints";

export const FellowshipPage = styled.div`
  width: 100%;
  min-height: 700px;
  color: ${colors.sherpa_blue};
  background: transparent;
  font-size: 20px;
  position: relative;
  padding-top: 85px;
  @media ${tabletRange} {
    padding-top: 80px;
  }
  & .container {
  padding: 0;
  max-width: ${containerMaxWidth};
  }
  @media ${mobileRange} {
  padding-top: 90px;
  }
`;

export const HeaderSection = styled.h1`
  font-size: 30px;
  font-weight: 300;
  margin-left: 75px;
  margin-bottom: 0;
  @media ${mobileRange} {
    text-align: center;
    margin: 70px 0 0;
  }
  @media ${tabletRange} {
    margin: 0 81px 0 40px;
  }
  @media ${ipadProRange} {
    margin: 0 30px;
  }
`;

export const MainSection = styled.div`
  background-color: ${colors.white};
  margin: 85px 0px;
  color: ${colors.scorpion};
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
  @media ${tabletRange} {
    margin: 85px 0px
  }
  @media ${mobileRange} {
    margin: 91px 0px;
    display: block;
  }
  @media ${ipadProRange} {
    margin: 70px 0px;
  }
`;

export const TitleWrapper = styled.h2`
  width: 100%;
  padding: 50px 20px 0 25px;
  font-size: 24px;
  color: ${colors.sherpa_blue};
  margin-bottom: 0;
  word-spacing: 2px;
  @media ${mobileRange} {
    padding: 40px 0;
    font-size: 22px;
    text-align: center;
  }
  @media ${tabletRange} {
    font-size: 24px;
  }
  span {
    font-style: italic;
  }
`;

export const ContentWrapper = styled.div`
  span {
    color: ${colors.gray};
    font-size: 20px;
    padding: 10px 22px;
    display: inline-block;
    
    @media ${mobileRange} {
      text-align: start;
      padding: 10px 12%;
    }
  }
  img {
    width: 100%; 
    height: auto; 
    display: block; 
    cursor: pointer;
    &:hover + div {
      visibility: visible; /* Show tooltip on image hover */
      opacity: 1; /* Fade in tooltip */
    }
  }
`;

export const DescriptionWrapper = styled.div`
  word-break: break-word;
  @media ${mobileRange} {
    padding: 0 25px;
    font-size: 15px;
  }
`;
export const Content = styled.p`
  margin-bottom: 0;
  ul {
    margin-bottom: 0;
  }
`;
export const GoToProgramWrapper = styled.div`
  font-size: 16px;
  padding-top: 15px;
  a {
    color: ${colors.bondi_green};
  }
`;

export const ReadMoreWrapper = styled.div`
  font-size: 16px;
  padding: 10px 0 0;
  color: ${colors.bondi_green};
  cursor: pointer;
`;

export const ContentWrapperMobile = styled.div`
  display: none;
  @media ${mobileRange} {
    display: flex;
    flex-direction: column;
    padding-bottom: 40px;
  }
`;

export const TitleWrapperMobile = styled.div`
  display: flex;  
  text-align: left;
  background: ${colors.light_darkish_blue};
  margin-top: ${props => props.index === 0 ? "0" : "40px;"}
`;

export const CompanyTitle = styled.div`
  width: 40%;
  padding: 20px 0 20px 26px;
  font-size: 16px;
  color: ${colors.sherpa_blue};
`;

export const FellowshipTitle = styled.div`
  width: 60%;
  padding: 20px 0 20px 26px;
  border-left: 1px solid rgba(112,112,112,0.2);
  font-size: 16px;
  color: ${colors.sherpa_blue};
`;

export const DescriptionWrapperMobile = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(112,112,112,0.2);
`;

export const ImageWrapperMobile = styled.div`
  width: 40%;
  padding-left: 10px;
  img {
    width: 120px;
  }
`;

export const FellowshipDescTitle = styled.p`
  width: 60%;
  font-size: 15px;
  margin-bottom: 0;
  padding: 24px;
  border-left: 1px solid rgba(112,112,112,0.2);
`;

export const SearchButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  @media ${mobileRange} {
    padding: 0 20px;
  }
  button {
    outline: none;
    background: ${colors.bondi_blue};
    color: ${colors.white};
    border: solid 1px ${colors.bondi_blue};
    border-radius: 5px;
    padding: 0px 20px;
    margin: 25px 0px;
    font-size: 14px;
    display: flex;
    align-items: center;

    img {
      width: 25px;
      height: 25px;

      @media ${mobileRange} {
        width: 20px;
        height: 20px;
      }

    }

    span {
      font-size: 14px;
      margin-left: 9px;
      color: ${colors.white};

      @media ${mobileRange} {
        padding: 0;
      }
    }
    @media ${mobileRange} {
     font-size: 14px;
      padding: 11px 20px;
      letter-spacing: 0.28px;
    }
    @media ${tabletRange} {
      font-size: 12px;
      padding: 5px 26px;
    }
  }
`;

export const DescriptionTitle = styled.div`
  padding-left: 27px;
  font-size: 16px;
  color: ${colors.sherpa_blue};
  margin: 15px 0;
`;

export const MobileBannerLink = styled.a`
  display: none;
  color: #4285F4 !important;
  background-color: #99E1D7;
  text-align: center;
  padding: 8px 12px;
  text-decoration: underline !important;
  cursor: pointer;

  @media ${mobileRange} {
    display: block;
    font-size: 12px;
  }

  @media ${tabletRange} {
    display: block;
    font-size: 16px;
  }
`;